import { put, takeLatest } from 'redux-saga/effects';
import { signup, signin, success, failed } from '../slice/userSlice';
import { postRequest, getRequest, deleteRequest, patchRequest } from './ApiInstence';
import { toast } from 'react-toastify';
import { getContactUs, successContactUs, failedContactUs, removeContactUs, getDashboardData } from '../slice/contactUsSlice';
import { getEnquire, successEnquire, failedEnquire, removeEnquire } from '../slice/enquireSlice';
import { addProduct, getProduct, updateProduct, successProduct, removeProduct, getOneProduct} from '../slice/productSlice';
import { getGallery, successGallery, removeGallery, addGallery, updateGallery, getOneGallery} from '../slice/gallerySlice';
import { getBanner, successBanner, removeBanner, addBanner, updateBanner, getOneBanner} from '../slice/bannerSlice';
import { failedCareer, getCareer, removeCareer, successCareer } from 'slice/careerSlice';
import { getOurteam, successOurteam, removeOurteam, addOurteam, updateOurteam, getOneOurteam, failedOurteam } from 'slice/ourteamSlice';

// Generator function
function* signinUser({ payload }) {
  try {
    // You can also export the axios call as a function.
    let response = yield postRequest('admin/signin', payload);
    if (response == undefined) {
      response = {
        message: 'something wrong try after sometime'
      }
    }
    if (response?.status == 200) {
      yield put(success(response));
    } else {

      yield put(failed(response));
    }
  } catch (error) {
    yield put(failed(error));
  }
}

function* signupUser({ payload }) {
  try {
    const response = yield postRequest('register', payload);
    if (response?.status == 200) {
      yield put(success(response?.data));
    } else {
      yield put(failed(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getContactUsFun({ payload }) {
  try {
    const response = yield getRequest('contactus', payload);
    if (response?.status == 200) {
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getDashboardDataFun({ payload }) {
  try {
    const response = yield getRequest('dashboard', payload);
    if (response?.status == 200) {
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}


function* getProductFun() {
  try {
    const response = yield getRequest('product');
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneProductFun({payload}) {
  try {
    const response = yield getRequest(`product/one/${payload}`);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeContactUsFun({ payload }) {
  try {
    const response = yield deleteRequest(`contactus/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('contactus', payload);
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeProductFun({ payload }) {
  try {
    const response = yield deleteRequest(`product/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('product', payload);
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addProductFun({ payload }) {
  try {
    const response = yield postRequest(`product`, payload);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateProductFun({ payload }) {
  try {
    const response = yield patchRequest(`product/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Gallery Functions 
function* getGalleryFun() {
  try {
    const response = yield getRequest('gallery');
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneGalleryFun({payload}) {
  try {
    const response = yield getRequest(`gallery/one/${payload}`);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeGalleryFun({ payload }) {
  try {
    const response = yield deleteRequest(`gallery/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('gallery', payload);
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addGalleryFun({ payload }) {
  try {
    const response = yield postRequest(`gallery`, payload);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateGalleryFun({ payload }) {
  try {
    const response = yield patchRequest(`gallery/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

// Enquire
function* getEnquireFun({ payload }) {
  try {
    console.log(payload.header)
    const response = yield getRequest('enquire', payload, payload.header);
    if (response?.status == 200) {
      yield put(successEnquire(response?.data));
    } else {
      yield put(failedEnquire(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeEnquireFun({ payload }) {
  try {
    const response = yield deleteRequest(`enquire/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('enquire', payload);
      yield put(successEnquire(response?.data));
    } else {
      yield put(failedEnquire(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getCareerFun({ payload }) {
  try {
    const response = yield getRequest('career', payload);
    if (response?.status == 200) {
      yield put(successCareer(response?.data));
    } else {
      yield put(failedCareer(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeCareerFun({ payload }) {
  try {
    const response = yield deleteRequest(`career/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('career', payload);
      yield put(success(response?.data));
    } else {
      yield put(failedCareer(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}



//Banner Start
function* getBannerFun() {
  try {
    const response = yield getRequest('banner');
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneBannerFun({payload}) {
  try {
    const response = yield getRequest(`banner/one/${payload}`);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeBannerFun({ payload }) {
  try {
    const response = yield deleteRequest(`banner/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('banner', payload);
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addBannerFun({ payload }) {
  try {
    const response = yield postRequest(`banner`, payload);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateBannerFun({ payload }) {
  try {
    const response = yield patchRequest(`banner/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Banner End 


//Ourteam Start
function* getOurteamFun() {
  try {
    const response = yield getRequest('ourteam');
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneOurteamFun({payload}) {
  try {
    const response = yield getRequest(`ourteam/one/${payload}`);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeOurteamFun({ payload }) {
  try {
    const response = yield deleteRequest(`ourteam/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('ourteam', payload);
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addOurteamFun({ payload }) {
  try {
    const response = yield postRequest(`ourteam`, payload);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateOurteamFun({ payload }) {
  try {
    let header =  {
      'Content-Type': 'multipart/form-data'
  }
    const response = yield patchRequest(`ourteam/${payload.id}`, payload.data, header);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Ourteam End 



// Generator function
export function* watchGetUser() {
  yield takeLatest(signin, signinUser);
  yield takeLatest(signup, signupUser);

  // contact us
  yield takeLatest(getContactUs, getContactUsFun);
  yield takeLatest(getDashboardData, getDashboardDataFun);
  yield takeLatest(removeContactUs, removeContactUsFun);

  //Enquire
  yield takeLatest(getEnquire, getEnquireFun);
  yield takeLatest(removeEnquire, removeEnquireFun);

  yield takeLatest(addProduct, addProductFun);
  yield takeLatest(getProduct, getProductFun);
  yield takeLatest(getOneProduct, getOneProductFun);
  yield takeLatest(removeProduct, removeProductFun);
  yield takeLatest(updateProduct, updateProductFun);

//gallery
  yield takeLatest(addGallery, addGalleryFun);
  yield takeLatest(getGallery, getGalleryFun);
  yield takeLatest(removeGallery, removeGalleryFun);
  yield takeLatest(getOneGallery, getOneGalleryFun);
  yield takeLatest(updateGallery, updateGalleryFun);

  //Banner
  yield takeLatest(addBanner, addBannerFun);
  yield takeLatest(getBanner, getBannerFun);
  yield takeLatest(removeBanner, removeBannerFun);
  yield takeLatest(getOneBanner, getOneBannerFun);
  yield takeLatest(updateBanner, updateBannerFun);

    //Our Team
    yield takeLatest(addOurteam, addOurteamFun);
    yield takeLatest(getOurteam, getOurteamFun);
    yield takeLatest(removeOurteam, removeOurteamFun);
    yield takeLatest(getOneOurteam, getOneOurteamFun);
    yield takeLatest(updateOurteam, updateOurteamFun);

  // Career 
  yield takeLatest(getCareer, getCareerFun);
  yield takeLatest(removeCareer, removeCareerFun);

}
import { createSlice } from '@reduxjs/toolkit'

const initialState = 
    {
        user: null,
        response: null,
        loading: false,
        error: null,
        callTimes: 0,
        message: '',
        data: []
    }

export const careerSlice = createSlice({
    name: 'career',
    initialState,
    reducers: {
        getDashboardData: () => {
        },
        getCareer: () => {
        },
        removeCareer: () => {
        },
        updateCareer: (state, action) => {
            state.value += action.payload
        },
        successCareer: (state, action) => {
            console.log(state)
            state.data = action.payload
        },
        failedCareer: (state, action) => {
            state.value += action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { getCareer, removeCareer, updateCareer, successCareer, failedCareer, getDashboardData } = careerSlice.actions

export default careerSlice.reducer
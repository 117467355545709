import React from 'react'
import 'App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRouter from './routers/PrivateRouter';
import OrderList from 'pages/OrderList';
import Contactus from 'pages/Contactus';
import Career from 'pages/Career'
import ProductList from 'pages/product/ProductList';
import AddProduct from 'pages/product/AddProduct';
import UpdateProduct from 'pages/product/UpdateProduct';

// gallery
import List from 'pages/gallery/List';
import Add from 'pages/gallery/Add';
import Update from 'pages/gallery/Update';
import Enquire from 'pages/Enquire';

// banner
import BannerList from 'pages/banner/List';
import AddBanner from 'pages/banner/Add';
import UpdateBanner from 'pages/banner/Update';

// Our Team
import AddOurteam from 'pages/ourteam/AddOurteam';
import ListOurteam from 'pages/ourteam/ListOurteam';
import UpdateOurteam from 'pages/ourteam/UpdateOurteam';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/signin" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </>
      <Routes>
        <Route element={<PrivateRouter />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/orderlist" element={<OrderList />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/career" element={<Career />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/update/:id" element={<UpdateProduct />} />

          {/* gallery */}
          <Route path="/gallery" element={<List />} />
          <Route path="/gallery/add" element={<Add />} />
          <Route path="/gallery/update/:id" element={<Update />} />

          {/* Enquire */}
          <Route path='enquire' element={<Enquire />} />

          {/* gallery */}
          <Route path="/banner" element={<BannerList />} />
          <Route path="/banner/add" element={<AddBanner />} />
          <Route path="/banner/update/:id" element={<UpdateBanner />} />

          {/* Our team */}
          <Route path="/ourteam" element={<ListOurteam />} />
          <Route path="/ourteam/add" element={<AddOurteam />} />
          <Route path="/ourteam/update/:id" element={<UpdateOurteam />} />

        </Route>
      </Routes>
    </Router>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
  callTimes: 0,
  message: '',
  data: []
}

export const ourteamSlice = createSlice({
  name: 'ourteamSliceName',
  initialState,
  reducers: {
    addOurteam: () => {

    },
    getOurteam: () => {

    },
    getOneOurteam: () => {

    },

    successOurteam: (state, action) => {
      console.log(state)
      state.data = action.payload
  },
    removeOurteam: () => {
    },
    updateOurteam: () => {
    },
    failedOurteam: (state, action) => {
      state.response += action.payload
  },
  },
})

// Action creators are generated for each case reducer function
export const { addOurteam, removeOurteam, updateOurteam, getOurteam, successOurteam, getOneOurteam, failedOurteam } = ourteamSlice.actions

export default ourteamSlice.reducer
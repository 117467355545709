import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addGallery } from 'slice/gallerySlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Add = () => {
    const [formData, setFormData] = useState({
        name: '',
        image: null,
    });

    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.product);
    const nevigate = useNavigate()

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? e.target.files[0] : value
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        try {
            dispatch(addGallery(formDataToSend))
            toast('Gallery added successfully!')
            nevigate('/gallery')
            setFormData({
                name: '',
                image: null,
            })
        } catch (error) {
            console.error('There was a problem adding the product:', error.message);
        }
    };

    return (
        <>
            <div className='container-fluid px-lg-5  card'>
            <div className='mainheadig'>
                <h4 className='text-white font-weight-bold '>Add Gallery</h4>
            </div>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-12 mt-2'>
                            <label htmlFor='productName'>Title</label>
                            <input type='text' id='productName' name='name' defaultValue={formData.name} className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-12 mt-2'>
                            <label htmlFor='productName'>Image</label>
                            <input type='file' id='productName' name='image' defaultValue={formData.image} className='form-control' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='mt-3 text-right'>
                        <button className='btn addbtn' disabled={loading}>Add Gallery</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Add
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import counterSlice from '../slice/counterSlice';
import authSlice from '../slice/authSlice';
import rootSaga from '../apis/rootSaga';
import  productSlice from '../slice/productSlice';
import userSlice from '../slice/userSlice';
import orderSlice from '../slice/orderSlice';
import contactUsSlice from '../slice/contactUsSlice';
import careerSlice from '../slice/careerSlice';
import gallerySlice from '../slice/gallerySlice';
import enquireSlice from '../slice/enquireSlice'
import bannerSlice from '../slice/bannerSlice';
import ourteamSlice from '../slice/ourteamSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    counter : counterSlice,
    auth: authSlice,
    product: productSlice,
    gallery: gallerySlice,
    enquire: enquireSlice,
    user: userSlice,
    order: orderSlice,
    contactus: contactUsSlice,
    career: careerSlice,
    banner: bannerSlice,
    ourteam: ourteamSlice
  },
  middleware: ()=>[sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
import React, { useState, useEffect } from 'react'
import signinimage from '../asserts/img/signin-image.jpg'
import { toast } from 'react-toastify';
import Button from '../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../slice/userSlice';
import { Navigate } from 'react-router-dom';

function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const {loading, error, response, callTimes, message } = useSelector(state => state.user);

    useEffect(() => {
        if (error) {
            if(message === undefined) {
                toast('something went wrong')                
            }
            else{
                toast(message)
            }
        }
    }, [response, error, callTimes]);

    const loginHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch(signin({ username: username, password: password }));
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <section className="sign-in mt-5">
            {response ? <Navigate to="/dashboard" replace={true} /> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row mt-5">
                                    <div className="col-lg-6 d-none d-lg-block text-center">
                                        <img src={signinimage} alt='admin Images'/>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"
                                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                                        placeholder="Enter Email Address..."  onChange={(e) => setUsername(e.target.value)}/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                        id="exampleInputPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Remember
                                                            Me</label>
                                                    </div>
                                                </div>
                                                <Button onclickfun={loginHandler} disabled={loading ? true : false} className="btn addbtn btn-user btn-block" text="Login"/>
                                                
                                                <hr />
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;
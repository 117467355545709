// Layout.js
import Button from 'components/Button';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from 'slice/userSlice';
import { useDispatch } from 'react-redux'; 
import logo from '../asserts/img/mylogo.png'
import { IoPersonCircleSharp } from "react-icons/io5";
import { MdContacts } from "react-icons/md";
import { AiFillFileText } from "react-icons/ai";
import { FaFileImage } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import { RiContactsBook3Fill } from "react-icons/ri";
// import Header from './Header';
// import Footer from './Footer';
// import { linklists, headersClasses } from './RouterList';
const currentUrl = window.location.pathname;
const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

const Layout = ({ children }) => {
    const [toggled, setToggle] = useState(false);
    const dispatch = useDispatch();
    const logoutHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch(logout());
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <>
            <div id="wrapper">
                <ul className={toggled ? 'navbar-nav  sidebar sidebar-dark toggled' : 'navbar-nav  sidebar sidebar-dark accordion'} id="accordionSidebar">
                    <Link className="sidebar-brand d-flex align-items-center justify-content-start" to="/dashboard">
                        <div className="">
                            <img  src={logo} alt='logo'  className='' width={140}/>
                        </div>
                        
                    </Link>
                    <hr className="sidebar-divider my-0" />
                    <li className="nav-item text-dark">
                        <Link className="nav-link " to="/dashboard">
                        <MdDashboard  className='mr-1'/>
                            <span >Dashboard</span></Link>
                    </li>
                    <hr className="sidebar-divider" />
                    <div className="sidebar-heading text-dark">
                        Interface
                    </div>
                    <li className={ `nav-item text-dark ${lastSegment == 'products' ? 'active' : ''}`}>
                        <Link className="nav-link text-dark collapsed" href="#" data-toggle="collapse" data-target="#products"
                            aria-expanded="true" aria-controls="contactus">
                           <RiProductHuntLine className='mr-1' />
                            <span>Product</span>
                        </Link>
                        <div id="products" className="collapse" aria-labelledby="headingTwo">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/products/add">Add Product</Link>
                                <Link className="collapse-item" to="/products">Product List</Link>
                            </div>
                        </div>
                    </li>
                    <li className={ `nav-item text-dark ${lastSegment == 'gallery' ? 'active' : ''}`}>
                        <Link className="nav-link text-dark collapsed" href="#" data-toggle="collapse" data-target="#gallery"
                            aria-expanded="true" aria-controls="contactus">
                           <FaFileImage className='mr-1' />
                            <span>Gallery</span>
                        </Link>
                        <div id="gallery" className="collapse" aria-labelledby="headingTwo">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/gallery/add">Add gallery</Link>
                                <Link className="collapse-item" to="/gallery">Gallery List</Link>
                            </div>
                        </div>
                    </li>
                    <li className={ `nav-item  text-dark ${lastSegment == 'ourteam' ? 'active' : ''}`}>
                        <Link className="nav-link  text-dark collapsed" href="#" data-toggle="collapse" data-target="#ourteam"
                            aria-expanded="true" aria-controls="ourteam">
                           <FaFileImage className='mr-1' />
                            <span>Our Team</span>
                        </Link>
                        <div id="ourteam" className="collapse" aria-labelledby="headingTwo">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/ourteam/add">Add ourteam</Link>
                                <Link className="collapse-item" to="/ourteam">ourteam List</Link>
                            </div>
                        </div>
                    </li>
                    <li className={ `nav-item text-dark ${lastSegment == 'banner' ? 'active' : ''}`}>
                        <Link className="nav-link text-dark collapsed" href="#" data-toggle="collapse" data-target="#banner"
                            aria-expanded="true" aria-controls="contactus">
                           <FaFileImage className='mr-1' />
                            <span>Banner</span>
                        </Link>
                        <div id="banner" className="collapse" aria-labelledby="headingTwo">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/banner/add">Add Banner</Link>
                                <Link className="collapse-item" to="/banner">Banner List</Link>
                            </div>
                        </div>
                    </li>
                    <hr className="sidebar-divider d-none d-md-block text-dark" />
                    <li className={ `nav-item  ${lastSegment == 'contactus' ? 'active' : ''}`}>
                        <Link className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#contactus"
                            aria-expanded="true" aria-controls="contactus">
                           <MdContacts className='mr-1 ' />
                            <span>Contact Us</span>
                        </Link>
                        <div id="contactus" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item text-dark" to="/contactus">Contact Us List</Link>
                            </div>
                        </div>
                    </li>
                    <li className={ `nav-item  text-dark ${lastSegment == 'enquire' ? 'active' : ''}`}>
                        <Link className="nav-link text-dark collapsed" href="#" data-toggle="collapse" data-target="#enquire"
                            aria-expanded="true" aria-controls="contactus">
                          <AiFillFileText  className='mr-1'/>
                            <span>Enquire</span>
                        </Link>
                        <div id="enquire" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/enquire">Enquire List</Link>
                            </div>
                        </div>
                    </li>
                    <li className={ `nav-item text-dark ${lastSegment == 'career' ? 'active' : ''}`}>
                        <Link className="nav-link text-dark collapsed" href="#" data-toggle="collapse" data-target="#career"
                            aria-expanded="true" aria-controls="contactus">
                           <RiContactsBook3Fill className='mr-1' />
                            <span>Resume</span>
                        </Link>
                        <div id="career" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/career">Resume List</Link>
                            </div>
                        </div>
                    </li>
                    <hr className="sidebar-divider" />
                  
                   
                   

                    <div className="text-center text-dark d-none d-md-inline">
                        <button className="rounded-circle border-0 text-dark" id="sidebarToggle" onClick={() => setToggle(!toggled)}></button>
                    </div>
                </ul>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars " ></i>
                            </button>
                            <ul className="navbar-nav ml-auto">
                        
                          
                             

                                <div className="topbar-divider d-none d-sm-block"></div>
                                <li className="nav-item dropdown no-arrow">
                                    <Link className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline  admintext small">Admin</span>
                                        <IoPersonCircleSharp  size={20} style={{color:"104990"}}/>
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <div className="dropdown-divider"></div>
                                        <Button className="dropdown-item" text='Logout' onclickfun={logoutHandler}/>
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    </div>
                                </li>

                            </ul>

                        </nav>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
